<template>
  <div>
    <Navbar
      @logout="logout()"
      :currentUser="currentUser"
      :routes="filteredRoutes"
    ></Navbar>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-lg-2 d-none d-lg-block">
          <Sidebar :routes="filteredRoutes"></Sidebar>
        </div>
        <div class="col">
          <div class="container-fluid">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { auth } from '../firebase';

export default {
  data() {
    return {
      currentUser: auth.currentUser,
      routes: [
        {
          name: 'studios',
          displayName: 'Studios',
          roles: ['bookkeeping', 'trainerCoordinator', 'marketing', 'admin'],
        },
        {
          name: 'holdings',
          displayName: 'Holdings',
          roles: ['bookkeeping', 'admin'],
        },
        {
          name: 'promotions',
          displayName: 'Promotions',
          roles: ['marketing', 'admin'],
        },
        {
          name: 'broadcasts',
          displayName: 'Broadcasts',
          roles: ['marketing', 'admin'],
        },
        {
          name: 'trainers',
          displayName: 'Trainer',
          roles: ['bookkeeping', 'trainerCoordinator', 'admin'],
        },
        {
          name: 'statistics',
          displayName: 'Statistiken',
          roles: ['admin', 'statistics'],
        },
        {
          name: 'bookkeeping',
          displayName: 'Buchhaltung',
          roles: ['bookkeeping', 'admin'],
        },
        {
          name: 'devices',
          displayName: 'Geräte',
          roles: ['devices', 'admin'],
        },
      ],
      filteredRoutes: [],
    };
  },
  methods: {
    async logout() {
      await auth.signOut();

      this.$router.push({ name: 'login' });
    },
  },
  async created() {
    const token = await auth.currentUser.getIdTokenResult();

    this.filteredRoutes = this.routes.filter((route) => {
      return route.roles.includes(token.claims.role);
    });
  },
  components: {
    Navbar,
    Sidebar,
  },
};
</script>
